export const statusList = [
  // { id: 1, name: "Mới tiếp nhận(đã bỏ)" },
  { id: 2, name: "Đã thực hiện" },
  { id: 3, name: "Không thực hiện được" },
];
export const styleStatus = {
  1: "bg-primary",
  2: "bg-success",
  3: "bg-warning",
};
export const channels = [
  {
    id: 1,
    name: "Email",
  },
  {
    id: 2,
    name: "Điện thoại",
  },
  {
    id: 3,
    name: "Văn bản",
  },
];
export const listType = [
  {
    id: 1,
    name: "Đặt thùng/sửa thùng",
  },
  {
    id: 2,
    name: "Biển báo",
  },
  {
    id: 3,
    name: "In biển báo",
  },
  {
    id: 4,
    name: "Yêu cầu khác",
  },
];
