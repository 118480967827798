import { AApi } from "@/service/AApi";

const API = new AApi({ url: "yeucau", isConvertFormData: true },
{
    exportExcel: {
        async request(data) {
          return this.sdk({
            url: "/request-export",
            responseType: "blob",
            params: data
          });
        },
      },
}
);
export default API